import React from "react";
import { useLocation } from "react-router-dom";
import Linerlist from "../components/Linerlist";

function Liner() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventid = queryParams.get("eventid");
  return <Linerlist eventid={eventid} />;
}

export default Liner;
