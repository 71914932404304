import { useState, useEffect } from "react";

function useApi() {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEvents = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}?function=getevents`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error: The status is ${response.status}`);
      }
      const data = await response.json();
      setEvents(data);
      setLoading(false);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  return { fetchEvents, events, loading, error };
}

export default useApi;
