import PriceinfoTextblock from "./PriceinfoTextblock";

export default function Priceinfo(props: any) {
  if (props.isevent) {
    return (
      <PriceinfoTextblock
        infotext="Einzelfahrt + Nightliner"
        ticketinfo="Südtirolpass und BrixenCard gültig"
        price={3}
      />
    );
  }
  return (
    <>
      <PriceinfoTextblock
        infotext="Einzelfahrt Hauptlinie"
        ticketinfo="Tickets sind in den Bussen sowie online erhältlich."
        price={3}
      />
      <br />
      <PriceinfoTextblock
        infotext="Nacht-Ticket für beliebig viele Fahrten mit den Nightlinern"
        //additionaltext="(Hauptlinien und Shuttle-Dienste)"
        ticketinfo="Tickets sind in den Bussen, bei den Ticketautomaten sowie online erhältlich."
        price={5}
      />
      <br />
      <PriceinfoTextblock
        infotext="Shuttle-Dienst"
        ticketinfo="Fahrten mit den Shuttlediensten (Zubringerdiensten) sind ab 16. Dezember 2022 kostenlos, kein Ticket erforderlich."
        //price={2}
      />
    </>
  );
}
