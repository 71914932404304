import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useApi from "../hooks/useApi";

function Events() {
  const { events, fetchEvents } = useApi();
  useEffect(() => {
    const title = "Nightliner - Home";
    if (document.title !== title) document.title = title;

    const fetchData = async () => {
      if (!events || events.length === 0) {
        await fetchEvents(); // This triggers the fetch if no events are loaded
      }
    };

    fetchData();
  }, [events, fetchEvents]);
  return (
    <div>
      {events?.map((event: any) => (
        <Button
          key={event.eventid} // Ensure each button has a unique key
          variant="contained"
          style={{
            backgroundColor: "rgba(255, 255, 255)",
            border: "1px solid white",
          }}
          sx={{ fontSize: 20 }}
          component={Link}
          to={`/liner?eventid=${event.id}`} // Dynamically set the link
        >
          <img src={event.logoname} width={200} alt={event.name} />
        </Button>
      ))}
    </div>
  );
}

export default Events;
